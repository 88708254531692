import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import IconManageAccess from "assets/images/icons/IconManageAccess";
import ActionButton from "components/common/ActionButton";
import Loader from "components/common/Loader";
import { useGetAppAccessQuery } from "services/api/app";
import { useAppSelector } from "stores";
import { selectAppUsers, selectIsAppAdmin } from "stores/features/app";
import { selectPrivateKey } from "stores/features/project";
import { SECTION } from "utils/constants";
import { useOrganizationDetails } from "utils/hooks";
import urls from "utils/urls";

import OrganizationMemberItem from "./OrganizationMemberItem";
import styles from "./styles.module.scss";

interface Props {
  readonly?: boolean;
  accessPage?: boolean;
}

const AppAccessUsers: React.FC<Props> = ({ accessPage, readonly }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const privateKey = useAppSelector(selectPrivateKey);
  const isAppAdmin = useAppSelector(selectIsAppAdmin);
  const appUsers = useAppSelector(selectAppUsers);
  const { isOrganizationOwner } = useOrganizationDetails();
  const { isLoading } = useGetAppAccessQuery(
    { privateKey },
    { skip: !privateKey }
  );

  if (isLoading && privateKey) {
    return <Loader className={styles.appAccessLoading} />;
  }

  return (
    <>
      <div className={styles.memberList}>
        {appUsers.map((appUser) => (
          <OrganizationMemberItem
            appUser={appUser}
            isAppAdmin={isAppAdmin}
            isOrgOwner={isOrganizationOwner}
            key={appUser.userId}
            readonly={readonly}
          />
        ))}
      </div>

      {!accessPage && (
        <ActionButton
          className={styles.button}
          icon={<IconManageAccess />}
          disabled={!isAppAdmin && !isOrganizationOwner}
          size="lg"
          type="primary"
          action={t("sections.overview.manageAccess")}
          onClick={() => {
            router.push(
              urls.appBuilder(privateKey, SECTION.access),
              undefined,
              { shallow: true }
            );
          }}
        />
      )}
    </>
  );
};

export default AppAccessUsers;
