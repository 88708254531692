import classNames from "classnames";
import React, { useMemo } from "react";

import { useAppSelector } from "stores";
import { selectUserId } from "stores/features/user";

import styles from "./styles.module.scss";
import { getColorFromString } from "./utils";

interface Props {
  name: string;
  userId: string;
}

const ProfilePicture: React.FC<Props> = ({ name, userId }) => {
  const currentUserId = useAppSelector(selectUserId);

  const backgroundColor = useMemo(() => {
    if (!name || !userId) {
      return "transparent";
    }
    if (currentUserId === userId) {
      return "hsla(22, 83%, 55%)";
    }
    return getColorFromString(userId);
  }, [currentUserId, name, userId]);

  return (
    <div
      className={classNames(
        styles.background,
        backgroundColor === "transparent" && styles.dashed
      )}
      style={{ backgroundColor }}
    >
      {backgroundColor !== "transparent" && (
        <span className={styles.initial}>{name[0]}</span>
      )}
    </div>
  );
};

export default ProfilePicture;
