/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import Loader from "components/common/Loader";
import ProfilePicture from "components/common/ProfilePicture";
import Select from "components/common/Select";
import { useAppDispatch, useAppSelector } from "stores";
import {
  AppAccessType,
  appUpdated,
  AppUser,
  selectAppUsers,
} from "stores/features/app";
import { selectPrivateKey } from "stores/features/project";
import api from "utils/api";
import { handleError } from "utils/errors";

import styles from "./styles.module.scss";

interface Props {
  appUser: AppUser;
  isOrgOwner?: boolean;
  isAppAdmin?: boolean;
  readonly?: boolean;
}

const OrganizationMemberItem: React.FC<Props> = ({
  appUser,
  isOrgOwner,
  isAppAdmin,
  readonly,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const privateKey = useAppSelector(selectPrivateKey);
  const appUsers = useAppSelector(selectAppUsers);

  const [isLoading, setIsLoading] = useState(false);

  const accessOptions = useMemo(
    () => [
      {
        label: t("sections.apps.filter.access.admin"),
        value: "admin",
      },
      {
        label: t("sections.apps.filter.access.developer"),
        value: "developer",
      },
      {
        label: t("sections.apps.filter.access.viewer"),
        value: "viewer",
      },
    ],
    [t]
  );

  const handleChange = useCallback(
    async (newValue) => {
      if (isLoading) return;
      setIsLoading(true);

      try {
        await api.app.updateUserAccess({
          appId: privateKey,
          userId: appUser.userId,
          access: newValue,
        });

        dispatch(
          appUpdated({
            users: appUsers.map((user) => {
              if (user.userId !== appUser.userId) {
                return user;
              }
              return { ...user, access: newValue };
            }),
          })
        );

        toast(t("sections.overview.appAccessUpdated"), { type: "success" });
      } catch (e) {
        handleError(e, { t });
      } finally {
        setIsLoading(false);
      }
    },
    [appUser, appUsers, dispatch, isLoading, privateKey, t]
  );

  const accessOptionSelected = useMemo(
    () => accessOptions.find(({ value }) => value === appUser.access),
    [appUser.access, accessOptions]
  );

  return (
    <div
      className={classNames(
        styles.memberItemContent,
        readonly && styles.dashboard
      )}
    >
      <div className={styles.name}>
        <ProfilePicture name={appUser.name} userId={appUser.userId} />
        {appUser.name}
      </div>
      <div>
        {readonly ? (
          <div className={styles.access}>{appUser.access}</div>
        ) : isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          <Select
            className={classNames(styles.appSelectFilter)}
            isDisabled={
              isLoading ||
              (!isOrgOwner && !isAppAdmin) ||
              appUser.organizationRole === "owner"
            }
            isSearchable={false}
            isLoading={isLoading}
            type="light"
            onChange={(e) => {
              if (e) {
                handleChange(e.value as AppAccessType);
              }
            }}
            options={accessOptions}
            value={accessOptionSelected}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationMemberItem;
