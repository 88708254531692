import React, { SVGProps } from "react";

const IconManageAccess: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.25 15H6.75C6.15326 15 5.58097 15.2371 5.15901 15.659C4.73705 16.081 4.5 16.6533 4.5 17.25V18.75H6V17.25C6 17.0511 6.07902 16.8603 6.21967 16.7197C6.36032 16.579 6.55109 16.5 6.75 16.5H11.25C11.4489 16.5 11.6397 16.579 11.7803 16.7197C11.921 16.8603 12 17.0511 12 17.25V18.75H13.5V17.25C13.5 16.6533 13.2629 16.081 12.841 15.659C12.419 15.2371 11.8467 15 11.25 15ZM9 14.25C9.59334 14.25 10.1734 14.0741 10.6667 13.7444C11.1601 13.4148 11.5446 12.9462 11.7716 12.3981C11.9987 11.8499 12.0581 11.2467 11.9424 10.6647C11.8266 10.0828 11.5409 9.54824 11.1213 9.12868C10.7018 8.70912 10.1672 8.4234 9.58527 8.30765C9.00333 8.19189 8.40013 8.2513 7.85195 8.47836C7.30377 8.70543 6.83524 9.08994 6.50559 9.58329C6.17595 10.0766 6 10.6567 6 11.25C6 12.0457 6.31607 12.8087 6.87868 13.3713C7.44129 13.9339 8.20435 14.25 9 14.25ZM9 9.75C9.29667 9.75 9.58668 9.83798 9.83336 10.0028C10.08 10.1676 10.2723 10.4019 10.3858 10.676C10.4994 10.9501 10.5291 11.2517 10.4712 11.5426C10.4133 11.8336 10.2704 12.1009 10.0607 12.3107C9.85088 12.5204 9.58361 12.6633 9.29264 12.7212C9.00166 12.7791 8.70006 12.7494 8.42598 12.6358C8.15189 12.5223 7.91762 12.33 7.7528 12.0834C7.58797 11.8367 7.5 11.5467 7.5 11.25C7.5 10.8522 7.65804 10.4706 7.93934 10.1893C8.22064 9.90804 8.60218 9.75 9 9.75Z"
      fill="currentColor"
    />
    <path
      d="M21 14.25V21H3V6H12V4.5H3C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V14.25H21Z"
      fill="currentColor"
    />
    <path
      d="M15 14.25H19.5V15.75H15V14.25ZM16.5 17.25H19.5V18.75H16.5V17.25ZM24 7.5V6H22.4243C22.3275 5.53052 22.1411 5.08411 21.8752 4.68525L22.9927 3.56775L21.9323 2.50725L20.8148 3.62475C20.4159 3.35888 19.9695 3.17248 19.5 3.07575V1.5H18V3.07575C17.5305 3.17248 17.0841 3.35888 16.6852 3.62475L15.5677 2.50725L14.5073 3.56775L15.6248 4.68525C15.3589 5.08411 15.1725 5.53052 15.0757 6H13.5V7.5H15.0757C15.1725 7.96948 15.3589 8.41589 15.6248 8.81475L14.5073 9.93225L15.5677 10.9927L16.6852 9.87525C17.0841 10.1411 17.5305 10.3275 18 10.4243V12H19.5V10.4243C19.9695 10.3275 20.4159 10.1411 20.8148 9.87525L21.9323 10.9927L22.9927 9.93225L21.8752 8.81475C22.1411 8.41589 22.3275 7.96948 22.4243 7.5H24ZM18.75 9C18.305 9 17.87 8.86804 17.5 8.62081C17.13 8.37357 16.8416 8.02217 16.6713 7.61104C16.501 7.1999 16.4564 6.7475 16.5432 6.31105C16.63 5.87459 16.8443 5.47368 17.159 5.15901C17.4737 4.84434 17.8746 4.63005 18.311 4.54323C18.7475 4.45642 19.1999 4.50097 19.611 4.67127C20.0222 4.84157 20.3736 5.12996 20.6208 5.49997C20.868 5.86998 21 6.30499 21 6.75C20.9994 7.34655 20.7622 7.9185 20.3403 8.34033C19.9185 8.76216 19.3466 8.9994 18.75 9Z"
      fill="currentColor"
    />
  </svg>
);

export default IconManageAccess;
